import {
  dateToCustomDate,
  type DateSelectionOption,
  type ProductNomenclatureType,
  type ReferentialIntervalsType,
} from '@carrefour-gcs/shared';
import dayjs from 'dayjs';

import type { FiltersStateType } from './kpisPerimeter.model';

import type { DatePeriod } from '@/domain/core/filter/period.model';
import { configureDayjs } from '@/helpers/date.helpers';

configureDayjs();

export const FILTERS_STATE_DEFAULT_VALUES: FiltersStateType = {
  nomenclatureFilters: [],
  activityTypesFilters: [],
  storesFilters: [],
  integratedFranchisedFilters: [],
  tryptiqueFilters: [],
  suppliersFilters: [],
  brandsFilters: [],
  bemHoldingFilters: [],
  itemFilters: [],
  barcodeFilters: [],
  storesRegionFilters: [],
};

// Distinguish empty filters state from default values
export const EMPTY_FILTERS_STATE: FiltersStateType = {
  nomenclatureFilters: [],
  activityTypesFilters: [],
  storesFilters: [],
  integratedFranchisedFilters: [],
  tryptiqueFilters: [],
  suppliersFilters: [],
  brandsFilters: [],
  bemHoldingFilters: [],
  itemFilters: [],
  barcodeFilters: [],
  storesRegionFilters: [],
};

export const DEFAULT_PERIOD_INTERVAL = {
  start: {
    year: dayjs().year(),
    month: dayjs().month() + 1,
    day: dayjs().date(),
  },
  end: {
    year: dayjs().year(),
    month: dayjs().month() + 1,
    day: dayjs().date(),
  },
};

export const DEFAULT_PERIOD_OPTIONS: DateSelectionOption = 'year_to_date';

export const DEFAULT_PERIOD_PERIOD: DatePeriod = 'monthly';

export const DEFAULT_REFERENTIAL: ProductNomenclatureType = 'carrefour';

export const DEFAULT_IS_WITH_TAX: boolean = false;

export const DEFAULT_PERIOD_REFERENTIAL_INTERVALS: ReferentialIntervalsType = {
  carrefour: {
    daily: {
      start: { year: 2004, month: 1, day: 28 },
      end: dateToCustomDate(dayjs().add(1, 'day').toDate()),
    },

    weekly: {
      start: { year: 2004, month: 1, day: 28 },
      end: dateToCustomDate(
        dayjs()
          .isoWeek(dayjs().isoWeek() - 1)
          .isoWeekday(7)
          .toDate(),
      ),
    },
    monthly: {
      start: { year: 2004, month: 1, day: 28 },
      end: dateToCustomDate(dayjs().endOf('month').toDate()),
    },
  },
};
