type ProjectUser = {
  id: number;
  email: string;
  fullName: string;
  countryAlpha2: string;
  companyName: string;
  externalId?: string;
};

type ProjectKind = {
  id: number;
  name: string;
};

export type ProjectRole = {
  id: number;
  name: string;
};

type ProjectContributor = {
  user: ProjectUser;
  role: ProjectRole;
};

type ProjectFilters = Record<string, any>;

type ProjectComparison = {
  id: number;
  comparisonFilters: ProjectFilters;
};

type ProjectGoal = {
  id: number;
  name: string;
  value: number;
};

type ProjectOutput = {
  id: number;
  name: string;
};

type ProjectProduct = {
  id: number;
  name: string;
  url: string;
};

export type ProjectWithRoles = {
  project: {
    id: number;
    createdAt: Date;
    name: string;
    description: string;
    supplierName?: string;
    startDate: Date;
    endDate: Date;
    owner: ProjectUser;
    kind: ProjectKind;
    contributors: ProjectContributor[];
    perimeterFilters: ProjectFilters;
    comparisons: ProjectComparison[];
    goals: ProjectGoal[];
    output?: ProjectOutput;
    product: ProjectProduct;
  };
  roles: ProjectRole[];
  contextualRoles: ProjectRole[];
};
import { z } from 'zod';

import { projectKindSchema } from './projectKind.model';

import { comparisonEntitySchema } from './projects.comparison.model';
import { contributorEntitySchema } from './projects.contributor.model';
import { filtersEntitySchema } from './projects.filters.model';
import { goalEntitySchema, goalEntityToCreateSchema } from './projects.goal.model';
import { productEntitySchema } from './projects.product.model';
import { projectOutputEntitySchema } from './projectOutput.model';
import { userEntitySchema, userEntityToCreateSchema } from './projects.user.model';

const projectEntitySchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().max(400),
  startDate: z.date(),
  endDate: z.date(),
  supplierName: z.string().optional(),
  kind: projectKindSchema,
  owner: userEntitySchema,
  createdAt: z.date(),
  contributors: z.array(contributorEntitySchema),
  perimeterFilters: filtersEntitySchema,
  comparisons: z.array(comparisonEntitySchema),
  goals: z.array(goalEntitySchema),
  output: projectOutputEntitySchema.optional(),
  product: productEntitySchema,
});

type ProjectEntityType = z.infer<typeof projectEntitySchema>;

const projectEntityToSaveSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(1),
  description: z.string().max(400),
  startDate: z.date(),
  endDate: z.date(),
  kindId: z.number(),
  supplierName: z.string().optional(),
  owner: userEntityToCreateSchema,
  perimeterFilters: filtersEntitySchema,
  comparisons: z.array(filtersEntitySchema),
  goals: z.array(goalEntityToCreateSchema),
  productId: z.number(),
});

type ProjectEntityToSaveType = z.infer<typeof projectEntityToSaveSchema>;

const projectEntityToShareSchema = z.object({
  projectId: z.number(),
  ownerEmail: z.string().email(),
  viewersEmails: z.string().array().min(1),
});

type ProjectEntityToShareType = z.infer<typeof projectEntityToShareSchema>;

export {
  projectEntitySchema,
  type ProjectEntityType,
  projectEntityToSaveSchema,
  type ProjectEntityToSaveType,
  projectEntityToShareSchema,
  type ProjectEntityToShareType,
};
